import { ApiResult, ApiService, useApiService } from '@innoit/api-service';
import { ApiEndpoint } from 'src/types';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';
type EndpointPath = ApiEndpoint extends `${HttpMethod}:${infer Path}`
  ? Path
  : never;

export const useApiWrapper = <T, K extends string>(
  endpoint: ApiEndpoint,
  dataKey: K
): Omit<ApiService<T>, 'result'> & { result: ApiResult<T, K> } => {
  const [method, endpointPath] = endpoint.split(':') as [
    HttpMethod,
    EndpointPath
  ];
  const { result, ...rest } = useApiService<T, EndpointPath>(
    method,
    endpointPath
  );

  const { data, status, error } = result;

  return {
    ...rest,
    result: { [dataKey]: data, status, error } as ApiResult<T, K>,
  };
};
