import { useCallback } from 'react';
import { BasicUserLoginDTO } from 'src/types';
import { useApiWrapper } from 'src/utils/use-api-wrapper';

interface LoginCredentials {
  username: BasicUserLoginDTO['username'];
  password: BasicUserLoginDTO['password'];
}

export const useBasicLogin = () => {
  const { dispatch, result } = useApiWrapper<string, 'data'>(
    'POST:/api/Auth/Login',
    'data'
  );

  const dispatchWrapper = useCallback(
    ({ username, password }: LoginCredentials) => {
      dispatch({ data: { username, password } });
    },
    [dispatch]
  );

  return { dispatch: dispatchWrapper, result };
};
